// ==============================
// Custom style
// 自定义样式
// ==============================

// .single{
//   .content{
//     blockquote {
//       display: block;
//       border-left: .5rem solid blue;
//       background-color: rgba(yellow, .9);
//       padding: .25rem .75rem;
//       margin: 1rem 0;

//       [theme=dark] & {
//         border-left-color: $blockquote-color-dark;
//         background-color: rgba($blockquote-color-dark, .2);
//       }
//     }
//   }
// }
